<template>
  <div class="peopleReactionForm">
    <div class="item">
      <div class="item-l">反映群众:<b>*</b></div>
      <div class="item-r">
        <v-input placeholder="请输入姓名" v-model="form.reactionUser"></v-input>
      </div>
    </div>
    <div class="item">
      <div class="item-l">手机号码:<b>*</b></div>
      <div class="item-r">
        <v-input
          v-model="form.reactionPhone"
          :maxlength="11"
          placeholder="请输入手机号码"
        ></v-input>
      </div>
    </div>
    <div class="item">
      <div class="item-l">年龄:<b>*</b></div>
      <div class="item-r">
        <v-input v-model="form.age" :maxlength="50" placeholder="请输入年龄"
          >></v-input
        >
      </div>
    </div>
    <div class="item te">
      <div class="item-l">事件描述:<b>*</b></div>
      <div class="item-r">
        <v-input
          v-model="form.description"
          placeholder="请填写事件描述"
          type="textarea"
          :maxlength="1000"
          :showWordLimit="true"
        ></v-input>
      </div>
    </div>
    <div class="item">
      <div class="item-l">居住地址:<b>*</b></div>
      <div class="item-r">
        <v-input disabled v-model="form.houseName" placeholder="请输入居住地址"
          >></v-input
        >
      </div>
    </div>
    <div class="item live" @click="toposition">
      <div class="item-t">
        <div class="item-l">事件地址:<b>*</b></div>
        <div class="item-r">
          <!-- <v-input
            v-model="form.detailedAddress"
            placeholder="请选择定位"
          ></v-input> -->
          <span>{{ form.detailedAddress }}</span>
          <img src="./img/arrow.png" alt="" />
        </div>
      </div>
      <div class="item-b">
        如涉及民生小事需交办处理的事件，请选择事件详细地点！
      </div>
    </div>
    <div class="item upload">
      <div class="item-l">照片<b>可上传图片、视频</b></div>
      <div class="item-r">
        <v-upload
          class="oItem-b_upload"
          :imgUrl.sync="form.picture"
          :activityPicture.sync="form.picture"
          :maxCount="9"
          ref="load"
        ></v-upload>
      </div>
    </div>
    <div class="info"><b>*</b>为必填项</div>
    <div :class="['submit', { disabled: disabled }]" @click="submit">
      点击提交
    </div>
  </div>
</template>

<script>
import { submitPeopleCongressReaction, userInfoUrl } from "./api.js";

export default {
  name: "peopleReactionForm",
  data() {
    return {
      disabled: false,
      form: {
        age: "",
        reactionUser: "",
        reactionPhone: "",
        detailedAddress: "",
        description: "",
        picture: "",
        houseName: "",
        houseId: "",
      },
      latitude: "",
      longitude: "",
      userInfo: {},
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  async created() {
    await this.getuserInfo();

    this.getSessionStorageData();
    if (this.$route.query.latitude) {
      this.latitude = this.$route.query.latitude;
    } else if (localStorage.getItem("latitude")) {
      this.latitude = localStorage.getItem("latitude");
    }
    if (this.$route.query.longitude) {
      this.longitude = this.$route.query.longitude;
    } else if (localStorage.getItem("longitude")) {
      this.longitude = localStorage.getItem("longitude");
    }
    if (this.$route.query.detailedAddress) {
      this.form.detailedAddress = this.$route.query.detailedAddress;
    }
  },
  mounted() {},
  methods: {
    async getuserInfo() {
      let params = {
        userId: this.userId,
      };
      let res = await this.$axios.get(`${userInfoUrl}`, { params: params });
      if (res.code == 200) {
        this.userInfo = res.data;
        if (this.userInfo && this.userInfo.chosenSpacePath) {
          this.form.houseName = this.userInfo.chosenSpacePath;
        }
        if (this.userInfo && this.userInfo.mobile) {
          this.form.reactionPhone = this.userInfo.mobile;
        }
      }
    },
    getSessionStorageData() {
      if (JSON.parse(sessionStorage.getItem("form"))) {
        this.form = JSON.parse(sessionStorage.getItem("form"));
      }
    },
    toposition() {
      sessionStorage.setItem("form", JSON.stringify(this.form));
      this.$router.push({
        name: "businessNewMap",
      });
    },
    submit() {
      console.log(this.form, "00");
      if (!this.form.reactionUser) {
        this.$toast({ message: "请填写名字", duration: 2000 });
        return;
      }
      if (!this.form.reactionPhone) {
        this.$toast({ message: "请填写手机号", duration: 2000 });
        return;
      }
      if (!this.form.age) {
        this.$toast({ message: "请填写年龄", duration: 2000 });
        return;
      }
      if (!this.form.description) {
        this.$toast({ message: "请填写描述", duration: 2000 });
        return;
      }
      if (!this.form.detailedAddress) {
        this.$toast({ message: "请选择地址", duration: 2000 });
        return;
      }
      let params = {
        age: this.form.age,
        description: this.form.description,
        detailedAddress: this.form.detailedAddress,
        latitude: this.latitude,
        longitude: this.longitude,
        picture: this.form.picture,
        reactionPhone: this.form.reactionPhone,
        reactionUser: this.form.reactionUser,
        tenantId: this.tenantId,
        userId: this.userId,
        houseName: this.form.houseName,
        houseId: this.roomId,
      };
      this.$axios
        .post(`${submitPeopleCongressReaction}`, params, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.code === 200) {
            this.$toast({ message: "提交成功", duration: 2000 });
            sessionStorage.removeItem("form");
            this.$router.go(-2);
          } else {
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.peopleReactionForm {
  min-height: 100vh;
  padding: 0 50px 76px;
  box-sizing: border-box;
  .item {
    display: flex;
    align-items: center;
    padding: 30px 0 32px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.04);
    position: relative;
    .item-l {
      font-weight: 400;
      font-size: 30px;
      color: #333333;
      line-height: 42px;
      width: 170px;
      b {
        color: #ff0200;
      }
    }
    .item-r {
      flex: 1;
      height: 44px;
      /deep/ .van-cell {
        height: 44px;
        box-sizing: border-box;
        padding: 0;
        .van-field__control {
          text-align: right;
        }
        .van-field__control:disabled {
          color: #333;
          -webkit-text-fill-color: #333;
        }
      }
    }
  }
  .te {
    display: block;
    .item-l {
      position: relative;
      b {
        color: #ff0200;
      }
    }
    .item-r {
      height: auto;
      padding-top: 20px;
      /deep/ .van-cell {
        min-height: 100px !important;
        .van-field__control {
          min-height: 100px !important;
          text-align: left;
        }
      }
    }
  }
  .live {
    display: block;
    padding: 30px 0 32px 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.04);
    position: relative;
    img {
      width: 10px;
      height: 16px;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    .item-t {
      display: flex;
      align-items: center;
      margin: 0 0 32px;
      position: relative;
      padding-right: 26px;
      .item-r {
        height: auto;
        span {
          font-weight: 400;
          font-size: 26px;
          color: rgba(0, 0, 0, 0.5);
          line-height: 36px;
        }
      }
    }
    .item-b {
      font-weight: 400;
      font-size: 22px;
      color: #ff0200;
      line-height: 32px;
    }
  }
  .upload {
    display: block;
    .item-l {
      display: flex;
      width: auto;
      align-items: center;
      margin-bottom: 26px;
      b {
        font-weight: 400;
        margin-left: 10px;
        font-size: 22px;
        color: rgba(0, 0, 0, 0.5);
        line-height: 32px;
      }
    }
    .item-r {
      height: auto;
    }
    border: 0;
  }
  .info {
    margin-top: 108px;
    font-weight: 400;
    font-size: 22px;
    color: #787878;
    line-height: 32px;
    text-align: center;
    b {
      color: #ff0200;
    }
  }
  .submit {
    width: 650px;
    height: 88px;
    background: #fe5c5b;
    border-radius: 44px;
    font-weight: 500;
    font-size: 36px;
    color: #ffffff;
    line-height: 88px;
    text-align: center;
    margin-top: 84px;
  }
  .disabled {
    background: #999;
  }
}
</style>
<style lang="less">
.workForm {
}
</style>
